@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

/*
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url('../Fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url('../Fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lovelace-Regular';
  src: local('Lovelace-Regular'), url('../Fonts/Lovelace/Zetafonts\ -\ Lovelace\ Text\ Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lovelace-Bold';
  src: local('Lovelace-Bold'), url('../Fonts/Lovelace/Zetafonts\ -\ Lovelace\ Text\ Bold\ Italic.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #527687;
  /* Montserrat Fallback */
  font-family: 'Montserrat', 'Verdana', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.app {
  flex: 1; 
}

.gradient-body {
  background-image: linear-gradient(white, #527687);
  padding-bottom: 0;
  min-height: 91vh !important;}

.Link {
  text-decoration: none;
  color: black;
}


button {
  background-color: #F6BBBC;
  border-radius: 30px;
  box-shadow: 0px 0px 1px #324b56;
  height: 35px;
  min-width: 120px; 
  border: none;
  font-family: 'Montserrat-Medium', sans-serif;
  padding: 10px 20px;
  font-size: 14px;
  color: rgb(53, 71, 81);
}

button:hover {
  cursor: pointer;
  transition: transform 0.3s ease; /* Add a smooth transition effect */
  background: linear-gradient(to bottom, #ffd6d6, #df8e90);
}

h1 {
  font-family: 'Lovelace-Regular', 'Garamond', serif;
  font-size: 2em;
  color: #527687;
  text-align: left;
  margin: 0;
  padding: 0;
}

h2 {
  font-family: 'Lovelace-Regular', 'Garamond', serif;
  font-size: 1.5em;
  font-weight: normal;
  text-align: left;
  margin: 0;
  padding: 0;
  color: rgb(53, 71, 81);
}

h3 {
  font-family: 'Montserrat-Medium', 'Verdana', Arial, sans-serif;
  font-size: 2em;
  color: #527687;
  text-align: left;
  margin: 0;
  padding: 0;
}

p {
  font-family: 'Montserrat', 'Verdana', Arial, sans-serif;
  font-size: 1em;
  font-weight: 450;
  text-align: left;
  margin: 0;
  padding: 0;
  color: rgb(53, 71, 81);
  line-height: 1.5;
}

li {
  font-family: 'Montserrat', 'Verdana', sans-serif;
  font-size: 1em;
  text-align: left;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  color: rgb(53, 71, 81);
  font-weight: 450;

}

