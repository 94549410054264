.portfolio-background {
    background-color: white;
    padding-bottom: 100px;
    padding-top: 0px;

}

.padding {
    padding-top: 20px;
    padding-bottom: 20px;

}

.noImage-main img {
    display: block;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0px;
    padding-top: 40px;
    width: 98%;
    max-width: 850px;
}

.oneimageinrow img {
    display: block;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    padding-top: 20px;
    width: 80%;
    max-width: 600px;
}



.you-may {
    width: 90%; /* Display one piece per row on mobile */}

.container {
    padding: 20px;
    display: block;
    align-items: center;
    text-align: center; 
    margin-left: auto;
    margin-right: auto;   
    padding-bottom: 0px;
    padding-top: 50px;
    background-color: aliceblue;
}

.container-noImage {
    padding: 20px;
    display: block;
    align-items: center;
    text-align: center; 
    margin-left: auto;
    margin-right: auto;   
    padding-bottom: 50px;
    padding-top: 50px;
    background-color: aliceblue;
}

.row h1, .row h2, .row-noImage h1, .row-noImage h2 {
    text-align: center; 
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 90%;
    max-width: 400px;
}

.portfolio-btn, .portfolio-btn-noImage {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 200px;
    height: 40px;
}

.left-image, .right-image{
    width: 90%;  
    max-width: 500px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
}

.mockup-image {
    animation: slideIn 1.7s ease-out; /* Adjust animation duration and timing function as needed */
    width: 98%;  
    max-width: 500px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
}


.mockup-image img, .left-image img, .right-image img {
    width: 100%;
    height: auto;
}

.port-about-container, .row-4, .row-1, .row-2, .row-3, .row-3-three {
    display: block;
    align-items: center;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;   
    padding-bottom: 20px;
    padding-top: 20px;
    width: 90%;
}

.port-about-title, .port-about-text {
    text-align: left; 
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 500px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.right-title, .right-text, .row-4-text {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 98%;
    max-width: 500px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.row-4-title {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    max-width: 500px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.project-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 90%;
    max-width: 500px;
    margin-top: 40px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.tag {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;

}


.project-tags img {
    width: 27px;
    height: 27px;
    margin-right: 10px;
}

.project-tags p {
    margin-right: 12px;
    margin-top: 5px;
    font-size: 14px;
}

.row-1, .row-2, .row-3, .row-4, .row-3-three {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

.row-3, .row-3-three {
    margin-top: 40px;
    max-width: 650px;
}

.row-3 img {
    width: 21%;
    height: auto;
    margin-bottom: 0px;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;
}

.row-3-three img {
    width: 30%;
    height: auto;
    margin-bottom: 0px;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: auto;
    margin-bottom: auto;

}

.row-3-two {
    margin-top: 40px;
    max-width: 850px;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    padding:0;
    width: 90%;
    display: block;
    justify-content: center;
    text-align: center;
  }
  
  .servicemenu, .asign {
    text-align: center;
    margin-top: 30px;
  }

  .servicemenu p, .asign p {
    text-align: center;
    margin-top: 30px;
  }




.row-3-two img {
    width: 100%;
    height: auto;
}

.left-pic, .right-pic {
    padding-right: 0px;
    padding-left: 0px;  
}

.middle-pic {
    padding-right: 4px;
    padding-left: 4px;
}



/*Tablet Styles*/
@media screen and (min-width: 700px) {
    .you-may {
        width: calc((100% / 3) - 20px); /* 3 pieces per row within 90% of the page width */
    }



    .you-may h2 {
        font-size: 17px;
    }

        .row-3, .row-3-three {
            width: 100%;
            max-width: 810px;
            padding: 0;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 70px;
            padding-top: 30px;
        }
    
        .row-3 img {
            width: 22%;
            height: auto;
            margin-right: 8px;
            margin-left: 8px;
            
        }

        .row-3-three img {
            width: 30%;
            height: auto;      
        }

        .left-pic, .right-pic {
            padding-right: 0px;
            padding-left: 0px;  
        }

        .middle-pic {
            padding-right: 15px;
            padding-left: 15px;
        }
    
        .port-about-container, .port-about-text, .port-about-title, .row-4, .row-1, .row-2, .project-tags, .row-4-title, .row-4-text {
            width: 95%;
            min-width: 700px;
            max-width: 850px;
            padding: 0;
            margin: 0;
            margin-left: auto;
            margin-right: auto;
        }
    
        .port-about-container, .row-4 {
            margin-top: 50px;
        }
        
        



        .row-1, .row-3 {
            display: flex;
            align-items: flex-start;
            margin-top: 30px;
        }
    
        .left-image {
            width: 55%;
            margin-right: 0px; /* Adjust margin as needed */
            margin-top: auto;
            margin-bottom: auto;
          }
    
        .left-image img {
            margin-left: auto;
            margin-right: auto;
            width: 93%;
            height: auto;
          }
          
          .right-text {
            flex: 1; /* Take up remaining space */
            display: flex;
            flex-direction: column; /* Stack items vertically */
            margin-right: 0px;
          }
          
          /* Optional styling */
          .right-title {
            margin-top: 20px; 
            margin-bottom: 0;
          }
    
        .port-about-text, .right-text, .row-4-text {
            margin-top: 20px;
        }
    
        .project-tags, .row-3 {
            margin-top: 50px;    
        }
    
}


/* Web Styles */
@media screen and (min-width: 975px) {
    .middle-pic {
        padding-right: 25px;
        padding-left: 25px;
    }


    .mockup-image {
        margin-right: 0px;
        max-width: 495px;
    }
    .container {
        padding: 20px;
        align-items: center;
        text-align: center; 
        margin-left: auto;
        margin-right: auto;   
        padding-bottom: 0px;
        padding-top: 50px;
    }

    .together-top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 90%;
        min-width: 800px;
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;  
    }
    



    .row h1, .row h2{
        text-align: left; 
        margin-left: 5px;
        margin-right: auto;
        margin-bottom: 20px;
        width: 340px;    }
    
    .portfolio-btn {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: -135px;
        width: 200px;
        height: 40px;
    }

    .row-3 {
        width: 80%;
        max-width: 810px;
        padding: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 70px;
        padding-top: 30px;
    }

    .row-3 img {
        width: 20%;
        height: auto;
        margin-right: 16px;
        margin-left: 16px;
        
    }

    .port-about-container, .port-about-text, .port-about-title, .row-4, .row-1, .row-2, .project-tags, .row-4-title, .row-4-text {
        width: 95%;
        min-width: 700px;
        max-width: 850px;
        padding: 0;
        margin: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .port-about-container, .row-4 {
        margin-top: 50px;
    }

    .row-1, .row-3 {
        display: flex;
        align-items: flex-start;
        margin-top: 30px;
    }

    .left-image {
        width: 90%; 
        flex: 0 0 auto; /* Don't grow or shrink, maintain initial width */
        margin-right: 0px; /* Adjust margin as needed */
        margin-top: auto;
        margin-bottom: auto;
      }

    .left-image img {
        margin-left: auto;
        margin-right: auto;
        width: 93%;
        height: auto;
      }
      
      .right-text {
        flex: 1; /* Take up remaining space */
        display: flex;
        flex-direction: column; /* Stack items vertically */
        margin-right: 35px;
      }
      
      /* Optional styling */
      .right-title {
        margin-top: 20px; 
        margin-bottom: 0;
      }

    .port-about-text, .right-text, .row-4-text {
        margin-top: 20px;
    }

    .project-tags, .row-3 {
        margin-top: 50px;    
    }

    .project-tags{
        margin-bottom: 50px;    
    }
    
    
    
}

