.header-container {
    padding: 20px;
    display: flex;
    align-items: center;
    background-image: url('../Assets/blue-water2.gif');
    background-size: cover; 
    background-position: center;
    height: 185px; /* Adjust header height as needed */
    text-align: center; 
}

.header-title {
    color: white;
    font-size: 2.5em;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    text-align: center;
}

.piece-title {
  font-size: 19px;
}

.category-bts {
    display: flex;
    justify-content: right;
    margin-right: 10%;
    margin-top: 35px;
}

.category-btn {
    background-color: white; 
    box-shadow: 0px 0px 1px #324b56;
    font-size: 12px;
    cursor: pointer;
    margin: 0;
    margin-right: 4px;
    margin-left: 4px;
    padding: 0;
    padding-left: 8px;
    padding-right: 8px;
    min-width: 80px !important;
}

.category-btnactive {
  background: #F6BBBC;
  box-shadow: 0px 0px 1px #324b56;  font-size: 12px !important;
  margin: 0;
  margin-right: 4px;
  margin-left: 4px;
  padding: 0;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 80px !important;
  border: none;
  font-family: 'Montserrat-Medium', sans-serif;
}

.portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
    max-width: 90%; 
    margin: 30px auto; 
    padding-top: 10px;
    gap: 20px; /* margin between pieces */
  }
  
  .portfolio-piece {
    width: calc((90% / 3) - 20px); /* 3 pieces per row within 90% of the page width */}
  
  .portfolio-link {
    text-decoration: none;
    color: inherit;
  }
  
  .portfolio-content {
    background-color: white;
    padding: 5px;
    box-shadow: 0px 0px 2px #324b56;
    display: flex; /* Use flexbox */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally*/

  }

  .portfolio-content:hover {
    transform: translateY(-2px);    
}

  .portfolio-image {
    flex: 0 0 auto; /* Let the image grow or shrink as needed */
  }

  .portfolio-image img {
    width: 100%; /* Take up the width of the container */
    height: auto; /* Automatically adjust height while maintaining aspect ratio */
    max-width: 100%; /* Ensure image stays within the container */
  }
  
  .portfolio-info {
    margin-top: 15px;
    margin-bottom: 10px;
    min-height: 77px;
  }
    

  .portfolio-info h2, .portfolio-info p {
    text-align: center; 
}

  .portfolio-info h2 {
    margin-bottom: 10px; 
}
  
  .portfolio-image img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }

  .back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px; /* Set the width and height of the button */
    height: 60px;
    background-color: white; /* Blue */
    color: #527687;
    border-radius: 50%; /* Make it a circle */
    text-align: center;
    line-height: 60px;
    font-size: 2em;
    font-weight: bolder;
    text-decoration: none;
    z-index: 9999; /* Ensure it appears above other elements */
    border: 3px solid #dfdfdf;
  }
  
  .back-to-top:before {
    content: '\2191'; /* Unicode arrow */
  }

  /*mobile styles*/
  @media (max-width: 768px) { 
    .category-bts {
        display: none;
    }
    .portfolio-piece {
      width: 90%; /* Display one piece per row on mobile */
    }
  
    .back-to-top {
      display: block; /* Show back to top arrow on mobile */
    }
  }

    /*tablet styles*/
    @media (min-width: 768px) and (max-width: 1024px) { 

        .category-btn {
        min-width: 65px;
        white-space: nowrap; /* Ensure the text stays on one line */
        width: min-content;

        }

        .category-btn:active {
          min-width: 65px;    
        }

        .category-bts {
          display: flex;
          justify-content: right;
          margin-right: 10%;
          margin-left: 10%;
          margin-top: 35px;
          width: 80%;
      }


      .portfolio-piece {
        width: calc((90% / 2) - 20px); /* Display two pieces per row on tablet */
      }
    
      .back-to-top {
        display: block; /* Show back to top arrow on tablet */
      }
    }
  