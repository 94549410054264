@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.home-image {
  animation: slideIn 1.7s ease-out; /* Adjust animation duration and timing function as needed */
}


.home-background {   
    background-image: url('../Assets/home_clouds.jpg');
    /* Set background size to cover the entire screen */
    background-size: cover;
    /* Center the background image horizontally and vertically */
    background-position: center center;
    /* Repeat the background image only horizontally and vertically */
    background-repeat: no-repeat;
    /* Set a fixed background attachment */
    background-attachment: fixed;  
    min-height: 80vh !important;
  }


.homeLogo {
    width: 50%;
    margin-left: 5%;
    margin-top: 15%;
    margin-bottom: 0;
  }
  
  .home-image {
    width: 100%;
    min-height: 300px;
    margin-bottom: 20%;
  }

  .tagline {
    color: white;
    text-shadow: 0px 2px 5px #182429;
    width: 90%;
    max-width: 400px;
    margin-top: 10%;
    margin-left: 5%;
    padding-top: 10px;
    line-height: 1.5em;
  }
  
  .home-content {
    margin-left: 0;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  
  .home-buttons {
    justify-content: center;
    padding-top: 10%;
    display: block;
    margin-top: 0%;
  }

  .home-btn {
    width: 93%;
    height: 50px;
    margin-bottom: 5%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.9em;
    color: rgba(57, 76, 86);
  }

  .round-div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 190px 0 190px 0;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  }

  .about-text {
    width: 80%;
    margin-left: auto; 
    margin-right: auto;
    margin-top: 15%;
  }

 .about-text p {
    text-align: justify; 
  }

  .about-text h1, .about-text h2 {
    text-align: left; 
  }

  .about-text h1 {
    text-align: center;
    margin-bottom: 40px; 
  }

  .about-text h2 {
    display: none;
  }

  .about-text p {
    margin-top: 20px; 
  }

  .about-content {
    display: block;
    text-align: center;
  }

  .headshot {
    width: 60%;
    position: relative;
    margin-top: 60px;
    margin-bottom: 50px;
  }
  

  .services {
    display: block;
    width: 98%;
    margin-top: 20%;
    margin-bottom: 20%;
    margin-left: auto;
    margin-right: auto;
  }

  .services  h2 {
    font-size: 0.9em;
    text-align: center;
  }

  .topRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 50px;

}

  .bottomRow {
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.viewMore {
  min-height: 140px;
  border-radius: 0;
  width: 31%;
  padding: 0;
  margin: 0;
  min-height: 140px;
  min-width: 100%;
  color: rgba(57, 76, 86);

}

.services-3 { /* view more button */
    min-height: 140px;
    width: 31%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.services-1, .services-2, .services-4, .services-5, .services-6 {
    min-height: 140px;
    background-color: white;
    width: 31%;
    padding: 0;
    box-shadow: 0px 0px 1px #324b56;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.services-1 {
    border-radius: 60px 0 0px 0; /*top-left, top-right, bottom-right, bottom-left*/
}

.services-6 {
    border-radius: 0 0 60px 0; /*top-left, top-right, bottom-right, bottom-left*/
}

.bottom {
    padding-top: 0;
    padding-bottom: 15%;
}


  .working-together {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    outline: 10px solid rgba(255, 255, 255, 0.5);
    background-color: #f4f4f4; /* Set the background color */
  }

  .working-together-text {
    margin-top: 30px;
    width: 80%;
    margin-bottom: 6%;

  }

  .headshot_round {
    width: 60%;
    height: auto;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }

  .working-together-text h2 {
    margin-bottom: 20px;
    margin-top: 10px;
    text-align: center;
    }

  .working-together-text p {
    margin-bottom: 30px;
    font-size: 1em;
    text-align: center;

  }

  .working-together button {
    width: 100%;
    height: 50px;
    color: rgba(57, 76, 86);
  }



/* Extra small mobile Breakpoint */
@media (max-width: 425px) {
  .tagline {
    font-size: 0.9em;
    line-height: 1.5em;
    width: 80%;
    max-width: 250px;
  }

  .home-btn {
    height: 40px;
    font-size: 14px;
  }

  .round-div {
    border-radius: 90px 0 90px 0;

  }

  .about-text h1 {
    font-size: 1.7em;
  }

  .about-text h2 {
    display: none;
  }

  .about-text p {
    margin-top: 20px; 
    font-size: 0.9em;
  }


  .headshot {
    width: 80%;
  }

  .services {
    width: 98%;
  }

  .services  h2 {
    font-size: 12px;
    text-align: center;
  }

.viewMore {
  min-height: 120px;
  font-size: 12px;
}

.services-3 { /* view more button */
    min-height: 120px;
}

.services-1, .services-2, .services-4, .services-5, .services-6 {
    min-height: 120px;
}

.services-1 {
    border-radius: 50px 0 0px 0; /*top-left, top-right, bottom-right, bottom-left*/
}

.services-6 {
    border-radius: 0 0 50px 0; /*top-left, top-right, bottom-right, bottom-left*/
}
}


/* Medium Breakpoint */
@media (min-width: 768px) {
    .homeLogo {
        width: 45%;
        max-width: 400px;
        margin-left: 8%;
        margin-top: 10%;
        margin-bottom: -5%;
      }
      
      .home-image {
        width: 100%;
        height: 340px;
        margin-bottom: 30%;
      }
    
      .tagline {
        width: 60%;
        max-width: 400px;
        margin-top: 0;
        margin-left: 8%;
        font-weight: lighter;
        line-height: 1.3em;
      }
      
      .home-content {
        margin-left: 0;
        margin-top: 10%;
        margin-bottom: 10%;
      }
      
      .home-buttons {
        display: flex;
        flex-direction: row; 
        justify-content: left;
        padding-top: 10%;
        margin-top: -5%;
        margin-left: 8%;
      }
    
      .home-btn {
        width: 180px;
        height: 40px;
        margin-bottom: 5%;
        margin-left: 0;
        margin-right: 15px;
      }
    
    
      .about-text {
        display: block;
        margin-bottom: 0;
        width: 50%;
        margin-left: 15px; 
        margin-right: 0;
        margin-top: 0;
    }
    
      .about-text h2 {
        margin-bottom: 20px; 
        display: none;
        font-size: 1.2em;
      }
    
      .about-text h1 {
        text-align: left;
        margin-bottom: 5%; 
        font-size: 1.3em;
      }
    
      .about-text p {
        font-size: 0.9em;
        margin-bottom: 10px; 
        text-align: left; 
      }
  

      .round-div {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 90px 0 90px 0; 
        padding-top: 30px;
      }
    
      
    
    
      .about-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 750px;   
        margin-bottom: 40px;   
        margin-top: 40px;
      }
    
      .headshot {
        width: 40%;
        height: auto;
        position: relative;
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 50px;
      }

    
      .services {
        margin-top: 20%;
        margin-bottom: 20%;
        display: block;
        width: 75%;
        margin-left: auto;
        margin-right: auto;
      }
    
      .services  h2 {
        font-size: 1.05em;
      }
    
      .bottomRow {
        margin-top: 3%;
    }
      
    .viewMore {
        min-height: 150px;
      }
    
    .services-1, .services-2, .services-3, .services-4, .services-5, .services-6 {
        min-height: 150px;
    }
    
    .services-1 {
        border-radius: 80px 0 0px 0; /*top-left, top-right, bottom-right, bottom-left*/
    }
    
    .services-6 {
        border-radius: 0 0 80px 0; /*top-left, top-right, bottom-right, bottom-left*/
    }
    
    
      .working-together {
        width: 85%;
        flex-direction: row;
        align-items: center;
        padding-top: 0px;
        padding-bottom: 20px;
      }
    
      .working-together-text {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        width: 45%;
        text-align: left;

      }
    
      .headshot_round {
        width: 35%;
        height: auto;
        margin-bottom: 20px;

      }
    
      .working-together-text h2 {
        text-align: left;
        margin-bottom: 20px;
        margin-top: 10px;
        font-size: 1.5em;
      }
    
      .working-together-text p {
        text-align: left;
        margin-bottom: 20px;
        font-size: 0.9em;
      }
    
      .working-together button {
        width: 70%;
        height: 40px;
    }
    

  }
  

  /* Web Breakpoint */
  @media (min-width: 993px) {
    
    .round-div {
      border-radius: 170px 0 170px 0; 
      padding-top: 10px;
      padding-bottom: 10px;
      margin-bottom: 150px;

    }

    

    .about-text h2 {
      margin-bottom: 20px; 
      display: none;
      font-size: 1.3em;
    }
  
    .about-text h1 {
      font-size: 2em;
      margin-top: 0px;
    }
  
    .about-text p {
      font-size: 1em;

    }
      
      .home-image {
        max-height: 490px;
        margin-bottom: 10%;
        height: 500px;
      }

      
      .home-content {
        margin-bottom: 10%;
      }
      
      .home-buttons {
        padding-top: 0%;
        margin-top: 3%;
      }

      .home-btn {
        width: 200px;
        font-size: 0.9em;
      }

      .tagline {
        padding-top: 0%;
        margin-top: 0%;
        font-weight: lighter;
        font-size: 1.9em;
        width: 60%;
        max-width: 500px;
      }

    .homeLogo {
        margin-bottom: -7%;
    }
    
    .headshot {
      max-width: 900px;
      margin-right: 0;
      }

      .about-content {
        display: flex;
        flex-direction: row;
        justify-content: center; 
        align-items: center; 
        max-width: 1200px;
        padding: 10px;
      }

      .about-text {
        max-width: 400px;
        width: 50%; 
        margin-right: 40px;
        margin-top: auto;
        margin-bottom: auto; 
        text-align: center; 
      }
  

      .services {
        max-width: 750px;
        margin-top: 10%;
        margin-bottom: 150px;

      }

      .services h2 {
        font-size: 1.3em;
      }

      .services-1, .services-2, .services-3, .services-4, .services-5, .services-6, .viewMore {
        min-height: 200px;
    }

    .bottom {
      padding-bottom: 3%;
  }

      .working-together {
        max-width: 750px;
        margin-bottom: 150px;
      }

      .working-together h2 {
        font-size: 1.9em;
      }

      .working-together p {
        font-size: 1em;
      }

      .working-together-text {
        width: 40%;
      }

  }
  
 