header {
    background-color: rgba(57, 76, 86, 0.8);
    padding: 20px;
    display: flex;
  align-items: center;
  position: sticky;
  top: 0; /* Stick to the top of the viewport */
  z-index: 1000;
}

.logo {
    margin-right: 10px; /* Adjust the margin for spacing between the logo and links */
    display: flex; /* Make the logo container a flex container */
    align-items: center; /* Center items vertically within the flex container */
  }

.logo-img {
    width: 150px;
    height: auto;
    margin-right: 0px;
}

.nav-links {
    list-style: none;
    display: flex; /* Make the list items (li) flex containers to arrange them horizontally */
    margin-right: auto; /* Push the list items to the left */
  }


.nav-link {
  font-family: 'Montserrat', sans-serif;
    text-decoration: none;
    color: white;
    padding: 15px; 

  }
  

  .nav-link:hover {
    color: #AEDEF3;
  }

  .social-icons {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  .icon {
    width: 40px; 
    height: auto;
    margin-left: 10px; /* Add some spacing between the icons */
  }

  .icon-linkedin {
    width: 37px; /* This one is a bit smaller on purpose */
    height: auto;
    margin-left: 10px;
  }
  
  
  .icon:hover, .icon-linkedin:hover {
    opacity: 0.8; 
    transform: scale(1.2); /* Increase the size of the icon on hover */
    transition: transform 0.3s ease; /* Add a smooth transition effect */
  }


  @media (min-width: 759px) {   /* Web styles */

    .mobile-menu {
      display: none; 
    }
  }


  @media screen and (max-width: 758px) { /* Mobile styles */
    .nav-links {
      display: none; 
    }

    .social-icons {
      display: none;
    }

    .mobile-menu {
      margin-left: auto;
    }

    
  }