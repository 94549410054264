.cont-email-a {
    color: white;
}

.contact-background {   
    background-color: rgba(57, 76, 86, 0.4);
    padding-bottom: 100px;
    padding-top: 30px;
    min-height: 95vh !important;
}

.contact-header-container {
    animation: slideIn 1.7s ease-out; /* Adjust animation duration and timing function as needed */
    padding: 20px;
    display: block;
    align-items: center;
    height: 185px; 
    text-align: center; 
    margin-left: auto;
    margin-right: auto;   
    padding-bottom: 60px;
    max-width: 450px;
}

.contact-h2 { 
    color: #fff;
    margin: 0;
    margin-top: 30px;
    font-size: 1.1em;
    margin-bottom: 35px;
    text-align: center; 
    margin-left: auto;
    margin-right: auto;
}


.contact-email {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.contact-email p {
    color: white;
}

.contact-email-icon {
    width: 60px;
    height: 60px;
}

.contact-form-container {
    padding: 20px;
    align-items: center;
    height: 185px; 
    text-align: center; 
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 60px;
    padding-top: 60px;
    padding-bottom: 60px;
    width: 85%;
    max-width: 450px;
    height: 450px;
}

.contact-error {
    color: red;
    margin-top: 5px;

}

.contact-form-container h2 {
    color: #394c56;
    font-size: 1.5em;
    margin-bottom: 20px;
}

input, textarea {
    width: 100%;
    min-width: 270px;
    max-width: 450px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 10px;
    border-radius: 10px;
    height: 50px;
    border: 2px solid #b8b8b8; /* Set the border properties */
    box-sizing: border-box;
}

textarea {
    height: 150px;
}

input::placeholder,
textarea::placeholder {
  text-indent: 5px; /* Adjust the value to move the placeholder text to the right */
}

textarea::placeholder {
    padding-top: 12px;
  }

.contact-btn {
    width: 85%;
    min-width: 270px;
    max-width: 450px;
    height: 45px;
    margin-top: 20px;
}

/* For tablets */
@media (min-width: 708px) {
    .contact-background {   
        background-color: rgba(57, 76, 86, 0.0);


    }

    .contact-background h1 {
        text-align: left;
        align-items: flex-start;
    }


    .contact-header-container {
        padding: 0px;
        display: block;
        align-items: flex-start;
        text-align: left; 
        margin-left: auto;
        margin-right: auto;   
        margin-top: 200px;
        margin-bottom: auto;
        width: 35%;
        max-width: 450px;
    }



    .contact-h2 { 
        color: #fff;
        margin: 0;
        margin-top: 30px;
        font-size: 17px;
        margin-bottom: 35px;
        text-align: left; 
        margin-left: auto;
        margin-right: auto;
    }

    .contact-email {
        text-align: left;
        justify-content: flex-start;
    }


    .contact-form-container {
        width: 370px;
    }

    .contact-background {
        display: flex;
    }

    input, textarea {
        width: 300px;
        font-family: 'Montserrat', sans-serif;
        margin-top: 10px;
        border-radius: 10px;
        height: 50px;
        border: 2px solid #b8b8b8; /* Set the border properties */
        box-sizing: border-box;
    }

    .contact-error {
        margin-left: 35px;
        margin-top: 5px;
    } 
    
    textarea {
        height: 150px;
    }
    
    .contact-btn {
        width: 300px;
        height: 45px;
        margin-top: 20px;
    }


}
/* For desktop */
@media (min-width: 900px) {
    .contact-error {
        margin-left: 18px;
        margin-top: 5px;
        min-width: 370px;
    } 

    .contact-background {   
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
    }

    .contact-h2 { 
        font-size: 18px;
    }


    .contact-form-container {
        min-width: 400px;
        width: 40%;
        max-width: 430px;
    }

    input, textarea {
        min-width: 370px;        
    }    

    .contact-btn {
        width: 370px;
        height: 45px;
        margin-top: 20px;
    }}

/* For desktop */
@media (min-width: 1050px) {
    .contact-error {
        margin-left: 33px;
        margin-top: 5px;
        min-width: 370px;
    } 


    
}
