.popup-container {
    width: 100%;
    position: relative;
    max-width: 1000px; /* Set a maximum width for the popup */
    background-color: white;
    padding: 10px;
    border-radius: 0px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    display: grid;
    place-items: center;
    overflow: hidden; /* Hide overflow content */
}

.close-button {
    position: absolute;
    top: 10px;
    right: -35px;
    background: none;
    border: none;
    box-shadow: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
}

.close-button:hover {
    background: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
}


@media screen and (min-width: 708px) {
    .popup-container {
        width: 85%;
        max-width: 800px;
    }
}

@media (min-width: 1108px) {
    .popup-container {
        width: 100%;
        max-width: 1000px;
    }
}

.popup-container img {
    max-width: 100%; /* Allow images to expand horizontally */
    max-height: 95vh; /* Set a maximum height for the popup container */
    object-fit: contain; /* Maintain aspect ratio */
}