.portfolio-background {
    background-color: white;
    padding-bottom: 100px;
    padding-top: 0px;

}

.padding {
    padding-top: 20px;
    padding-bottom: 20px;

}


.you-may {
    width: 90%; /* Display one piece per row on mobile */}

.container {
    padding: 20px;
    display: block;
    align-items: center;
    text-align: center; 
    margin-left: auto;
    margin-right: auto;   
    padding-bottom: 0px;
    padding-top: 50px;
    background-color: aliceblue;
}

.container-noImage {
    padding: 20px;
    display: block;
    align-items: center;
    text-align: center; 
    margin-left: auto;
    margin-right: auto;   
    padding-bottom: 50px;
    padding-top: 50px;
    background-color: aliceblue;
}

.vertical-row h1, .vertical-row h2, .row-noImage h1, .row-noImage h2 {
    text-align: center; 
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 90%;
    max-width: 400px;
}

.portfolio-btn, .portfolio-btn-noImage {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 200px;
    height: 40px;
}

.vertical-left-image {
    width: 100%;  
    max-width: 500px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
}


.vertical-left-image img {
    width: 100%;
    height: auto;
}

.vertical-row-1 {
    display: block;
    align-items: center;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;   
    padding-bottom: 20px;
    padding-top: 20px;
    width: 90%;
}



.vertical-right-title, .vertical-right-text {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 98%;
    max-width: 500px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.vertical-project-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 90%;
    max-width: 500px;
    margin-top: 40px;
    margin-bottom: 10px;
    margin-left: 0;}

.vertical-tag {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;

}


.vertical-project-tags img {
    width: 27px;
    height: 27px;
    margin-right: 10px;
}

.vertical-project-tags p {
    margin-right: 12px;
    margin-top: 5px;
    font-size: 14px;
}

.vertical-row-1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -10px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}


/*Tablet Styles*/
@media screen and (min-width: 700px) {

    .you-may {
        width: calc((100% / 3) - 20px); /* 3 pieces per row within 90% of the page width */
    }

    .you-may h2 {
        font-size: 17px;
    }

    .vertical-row-1 {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center; /* Center content horizontally */
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;
      min-width: 300px;
      max-width: 900px;

    }
  
    .vertical-left-image {
      flex: 0 0 auto; /* Don't grow or shrink, maintain initial width */
      margin-right: 20px; /* Adjust margin as needed */
      width: 400px;
    }
  
    .vertical-left-image img {
      width: 100%; /* Make sure the image fills the container */
      height: auto;
    }
  
    .vertical-right-content {
      flex: 1; /* Take up remaining space */
      display: flex;
      flex-direction: column; /* Stack items vertically */
    }
  

    /* Optional styling */
    .vertical-right-title {
      margin-top: 12px;
      margin-bottom: 0;
    }
  
    .vertical-right-text {
      margin-top: 20px;
    }
  
    .vertical-project-tags {
      margin-top: 40px;
    }
  }


/*Desktop Styles*/      
@media screen and (min-width: 800px) {

    .vertical-row-1 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center; /* Center content horizontally */
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        min-width: 300px;
        max-width: 850px;
  
      }
    
      .vertical-left-image {
        flex: 0 0 auto; /* Don't grow or shrink, maintain initial width */
        margin-right: 20px; /* Adjust margin as needed */
        width: 80%;
      }
  }
