/* mobile styles */
.resume-email-a {
    color: black;
}

.underline {
    display: inline-block;
    border-bottom: 4px solid #AEDEF3;
    margin: 0 25px;
}

.horizontal-line {
    border-top: 1px solid #F1F1F1; 
    margin: 20px 0; 
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}



.resume-p  {
    margin: 0 25px;
}

.resumeHeader {
    position: relative;
    width: 100%;
}

.resumeHeadshot {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 20px 20px 0 0; /*top-left, top-right, bottom-right, bottom-left*/
}


.pers-info-container, .prof-summary, .Experience, .Education, .certifications {
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 25px auto;
    background-color: white;
    border-radius: 20px;
    width: 93%;
    box-shadow: 0px 0px 1px 8px rgba(255, 255, 255, 0.4);
}

.white-header {
    color: white;
    font-size: 1.5em;
    margin-left: 25px;
    font-weight: normal;
}

.resume-btn {
    margin-right: 25px;
    height: 35px;
    min-width: 120px; 
    padding: 5px 30px;
}

.transparent-header {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 93%;
    margin-top: -78px;
    background-color: rgba(57, 76, 86, 0.7);
    padding: 20px 0;}

.pers-info-icons img {
    width: 30px;
    height: 30px;
    margin-left: 25px;
}

.location, .email, .phone {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
}

.location {
    margin-top: 25px;
}

.skills {
    margin-bottom: 25px;
}

.skills h2 {
    margin-top: 25px;
}

.prof-summary h2, .Experience h2, .Education h2 {
    margin-top: 25px;
    margin-bottom: 25px;
}

.prof-summary p {
    margin-bottom: 30px;}



.tspa {
    margin-bottom: 40px;
}

.briefcase-icon {
    width: 40px;
    height: 40px;
    margin-left: 25px;
}

.resumeRow {
    display: flex;
    flex-direction: row;
}

.Experience ul {
    margin-bottom: 40px;
    margin-right: 25px;
    margin-left: -15px;
    font-size: 14px; /* This will need to change per each breakpoint */
}

.honors {
    margin-top: 20px;
}

.vertical-line { /* This will need to change per each breakpoint */
    border-left: 5px solid #F1F1F1; 
    height: 200px; 
    margin: 60px 39px; 
}

.left-column {
    width: 17%;
}

/* For smallest mobile phones: */
@media (max-width: 400px) {
    .resume-header {
        align-items: center;
    }

    .white-header {
        color: white;
        font-size: 1.3em;
        font-weight: normal;
        margin-bottom: 10px;
        width: 100%;
        margin-left: 0;
        text-align: center;
    }
    
    .resume-btn {
        margin-right: 0;
        height: 40px;
        width: 180px;
        padding: 5px 0px;
        font-size: 14px;
    }
    
    .transparent-header {
        position: absolute;
        display: block;
        width: 92.9%;
        margin-top: -100px;
        padding: 10px 0;
        text-align: center;}
        
    
}

/* For larger mobile phones: */
@media (min-width: 400px) {
    .resume-header {
        align-items: center;
    }

    .white-header {
        margin-top: 5px;
        width: 100%;
        margin-left: 15px;
        text-align: left;
    }
    
    .resume-btn {
        margin-right: 15px;
        height: 40px;
        width: 300px;
        padding: 5px 0px;
        font-size: 14px;
    }
    
    .transparent-header {
        position: absolute;
        display: flex;
        width: 92.9%;
        margin-top: -84px;
        padding: 20px 0;
        text-align: center;
    }
        
    
}



/* For tablets */
@media (min-width: 708px) {

    p {
        font-size: 14px;
    }
    
    .skills ul{
        margin-right: 20px;
        font-size: 14px;
    }


    .column-container {
        display: flex;
        flex-direction: row;
        margin-left: auto;
        margin-right: auto;
        width: 98%;
        max-width: 796px;
        margin-right: 0;
        margin: 0 auto;
    }

    .pers-info-container, .resume-header {
        max-width: 400px;
        min-width: 300px;

    }

    .horizontal-rows {
        margin-left: 5px;
        max-width: 800px;
    }

    .transparent-header {
        max-width: 200px;
        min-width: 290px;
        margin-top: -79px;
        padding-right: 10px;
    }

    .white-header {
        font-size: 1.2em;
        margin-left: 10px;
        padding-right: 0px;
        width: 130px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .resume-btn {        
        min-width: 120px; 
        height: 35px;
        padding: 0px 0px;
        margin-right: 0px;
        margin-left: 0px;
        width: 140px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
  
  /* For laptops */
  @media (min-width: 1108px) {
    .pers-info-container, .resume-header {
        min-width: 400px;
        max-width: 400px;
    }

    .transparent-header {
        min-width: 390px;
        max-width: 390px;
    }

    .white-header {
        font-size: 1.3em;
        margin-left: 25px;
    }

    .resume-btn {
        margin-right: 5px;
        margin-left: 18px;
        width: 170px;
        font-size: 14px;
    }

    .column-container {
        width: 98%;
        max-width: 1000px;
    }

    .prof-summary h2, .Experience h2, .Education h2 {
        margin-top: 30px;
    }

    .prof-summary p {
        padding-bottom: 12px;
    }

    .resumeRow {
        margin-top: 10px;

    }

    .ucf {
        margin-top: 10px;
    }

    .Education {
        padding-bottom: 52px;
    }
    


  }

